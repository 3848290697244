<template>
    <page-title
        title="応援カメラマン用パスワード管理"
        icon="bi-shield-lock"
    >
    </page-title>

    <section class="section">
        <form class="row" @submit.prevent="save">
            <div class="form-group col-md-6">
                <label>パスワード</label>
                <form-input
                    v-if="visible"
                    v-model="password"
                    :required="true"
                    pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,1000}$"
                ></form-input>
                <form-input-password
                    v-if="!visible"
                    v-model="password"
                    :required="true"
                    pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,1000}$"
                ></form-input-password>
                <small class="text-muted">大文字を含む半角英数字8文字以上</small>
            </div>
            <div class="form-group col-md-2 align-self-center">
                <div class="form-check form-switch">
                    <input
                        v-model="visible"
                        type="checkbox"
                        class="form-check-input"
                        id="visibleCheckBox"
                        :true-value="true"
                        :false-value="false"
                    >
                    <label class="form-check-label" for="visibleCheckBox"><i class="bi bi-eye"></i></label>
                </div>
            </div>
            <div class="col-4 align-self-center">
                <button-exec-update
                    size="btn-lg"
                    text="変更"
                ></button-exec-update>
            </div>
        </form>
    </section>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import FormInput from '@/components/forms/FormInput';
import FormInputPassword from '@/components/forms/FormInputPassword';

export default {
    name: 'PartnerPassword',
    components: {
        PageTitle,
        ButtonExecUpdate,
        FormInput,
        FormInputPassword
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            password: null,
            visible: false,
        }
    },
    methods: {
        save() {
            this.startScreenLoading();
            this.$http.post(`/passwords`, {
                password: this.password
            })
            .then(() => {
                this.showMessage('変更しました');
                this.password = null;
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
